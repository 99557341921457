<template>
    <div class="border border-indigo-600 min-h-screen my-2">
        <div class="flex justify-between p-8">
            <div class="flex flex-col">
                <p>{{ clientInfo.name }}</p>
                <p>Org.nr {{ clientInfo.registrationNumber }}</p>
            </div>
            <p>5 (8)</p>
        </div>
        <div class="px-8 pb-8">
            <div class="flex flex-row" v-if="data && data.period && data.previousPeriod">
                <h1 class="font-bold mb-2" style="width: 450px">{{ title }}</h1>
                <h1 class="font-bold mb-2 w-20">Not</h1>
                <h1 class="font-bold mb-2 break-normal w-28 text-right">{{ formatDate(data.period.endDate) }}</h1>
                <h1 class="font-bold mb-2 break-normal w-28 text-right">{{ formatDate(data.previousPeriod.endDate) }}</h1>
            </div>
            <div v-for="(row, index) in tableData" :key="index" class="flex flex-row text-sm space-y-2 items-center">
                <div class="flex flex-col break-normal" style="width: 450px">
                    <p :class="{ 'font-bold pb-2': (row.name && row.name === row.name.toUpperCase()) || handleName(row.name), 'break-normal': row.name }">
                        {{ row.name }}
                    </p>
                </div>
                <div class="flex flex-col w-20 pb-2">
                    <p><!--NOT--></p>
                </div>
                <div v-for="(amount, index) in getValues(row)" :key="index" class="flex flex-col w-28 text-right">
                    <div v-if="row.type !== 3">
                        <p v-if="amount == 0" :class="{ 'font-bold pb-2': row.name && row.name === row.name.toUpperCase() }">0</p>
                        <p v-else :class="{ 'font-bold pb-2': row.name && row.name === row.name.toUpperCase() }">{{ amount | swedishNumberFormat(true) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    props: {
        clientId: {
            type: String,
        },
        clientInfo: {
            type: Object,
        },
        data: {
            type: Object,
        },
        tableData: {
            type: Array,
        },
        title: {
            type: String,
        },
    },

    methods: {
        formatDate(date) {
            if (!date) return " ";
            return moment(date).format("YYYY.MM.DD");
        },
        getValues(row) {
            return [row.periodRounded, row.previousPeriodRounded];
        },
        handleName(rowValue) {
            return rowValue === "Bundet eget kapital" || rowValue === "Fritt eget kapital";
        },
    },
};
</script>
